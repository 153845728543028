











import { Component, Vue, Watch } from "vue-property-decorator";
import Banner from "@/components/Banner/Banner.vue";
import NavigationTop from "@/components/NavigationTop/NavigationTop.vue";
import NavigationBottom from "@/components/NavigationBottom/NavigationBottom.vue";

@Component({
  components: {
    Banner,
    NavigationTop,
    NavigationBottom,
  },
})
export default class WwwNowigoApp extends Vue {
  @Watch("$route", { immediate: true, deep: true })
  private onUrlChange(): void {
    scroll(0, 0);
  }
}
