import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "BASE_URL",
    beforeEnter(to, from, next) {
      next("/accueil");
    },
    meta: {
      title: "Nowigo App",
    },
  },
  {
    path: "/accueil",
    name: "Home",
    component: Home,
    meta: {
      title: "Accueil - Nowigo App",
    },
  },
  {
    path: "/le-projet",
    name: "Projet",
    component: () => import("../views/Project.vue"),
    meta: {
      title: "Le projet - Nowigo App",
    },
  },
  {
    path: "/les-reseaux",
    name: "Networks",
    component: () => import("../views/Networks.vue"),
    meta: {
      title: "Les réseaux - Nowigo App",
    },
  },
  {
    path: "/app",
    name: "App",
    component: () => import("../views/App.vue"),
    meta: {
      title: "L'application - Nowigo App",
    },
  },
  {
    path: "/legales",
    name: "Legacy",
    component: () => import("../views/Lecacy.vue"),
    meta: {
      title: "Mentions légales & C.G.U - Nowigo App",
    },
  },
  {
    path: "/donnees-de-transport",
    name: "LegacyDataTransport",
    component: () => import("../views/LegacyDataTransport.vue"),
    meta: {
      title: "Données de transports - Nowigo App",
    },
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("../views/Policy.vue"),
    meta: {
      title:
        "Gestions des données personnelles et politique de confidentialité - Nowigo App",
    },
  },
  {
    path: "/statut-des-services",
    name: "Status",
    meta: {
      title: "Statut des services - Nowigo App",
    },
    beforeEnter() {
      window.location.href = "https://status.nowigo-app.fr";
    },
  },
  {
    path: "/api",
    name: "Api",
    meta: {
      title: "API - Nowigo App",
    },
    beforeEnter() {
      window.location.href = "https://data.nowigo-app.fr";
    },
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      title: "Page non trouvée - Nowigo App",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  const title = to?.meta?.title ?? "Nowigo App";
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
