




































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class NavigationTop extends Vue {
  private toggleState = false;

  private toggleRoutes(): void {
    this.toggleState = !this.toggleState;
  }

  @Watch("$route", { immediate: true, deep: true })
  private onUrlChange(): void {
    this.toggleState = false;
  }
}
