













import { Component, Vue } from "vue-property-decorator";

@Component
export default class PromotionNetwork extends Vue {}
