









import { Component, Vue } from "vue-property-decorator";
import Hero from "@/sections/Home/Hero/Hero.vue";
import PromotionNetwork from "@/sections/Home/PromotionNetwork/PromotionNetwork.vue";
import TravelerExplain from "@/sections/Home/TravelerExplain/TravelerExplain.vue";
import DownloadApp from "@/sections/Home/DownloadApp/DownloadApp.vue";

@Component({
  components: {
    Hero,
    PromotionNetwork,
    TravelerExplain,
    DownloadApp,
  },
})
export default class Home extends Vue {}
